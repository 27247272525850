import {
  Box,
  ChakraBaseProvider,
  Flex,
  Grid,
  GridItem,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
  Image,
} from "@chakra-ui/react";

import theme from "./theme";
import Footer from "./components/Footer";
import NavBar from "./components/NavBarLanding";
import { Web3Provider } from "./components/Web3Provider";
import { CheckCircleIcon } from "@chakra-ui/icons";
import Logo from "./components/Logo"; // Added import for Logo

import BlockchainBlocks from "./assets/blockchain-blocks.png";
import Chained from "./assets/chained.png";
import Compact from "./assets/compact.png";
import Stacked from "./assets/stacked.svg";

// SVG Icon Components
const OnChainIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2L2 7v10l10 5 10-5V7l-10-5z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
    <path d="M2 7l10 5 10-5M2 17l10-5 10 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const IdentityFabricIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"/>
    <path d="M12 2v20M2 12h20" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
  </svg>
);

const SecurityIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 12h6M12 9v6M21 12c0 4.97-4.03 9-9 9s-9-4.03-9-9c0-3.87 2.43-7.16 5.95-8.43L12 3l2.05 0.57C18.57 4.84 21 8.13 21 12z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const AccessControlIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 12l5 5 5-5M12 17V7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5 12a7 7 0 1114 0 7 7 0 01-14 0z" stroke="currentColor" strokeWidth="2"/>
  </svg>
);

const DecentralizedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2v20M2 12h20" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path d="M17 7l-5 5-5-5M7 17l5-5 5 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const UserCentricIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 12a4 4 0 100-8 4 4 0 000 8zM6.34 18a8.36 8.36 0 0111.32 0" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2 22h20" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
  </svg>
);

export function Landing() {
  return (
    <ChakraBaseProvider theme={theme}>
      <Web3Provider>
        <NavBar />
        <Main />
      </Web3Provider>
    </ChakraBaseProvider>
  );
}

function Main() {
  return (
    <>
      <Flex direction={{ base: "column", md: "column" }} wrap="wrap" justify="center" align="center">
        

        <Box flex="4">
          <Flex direction="column" align="center" justify="center" mb={10}>
            <Logo width="80%" alt="dgenDID Concept" />
            <Text fontSize="xl" my={2}>
              Revolutionizing Digital Identity for the Decentralized Web
            </Text>
          </Flex>
        </Box>

        <Grid flex={5} mr={100} ml={100} templateColumns="repeat(3, 1fr)" gap={6}>
          <GridItem colSpan={1}>
            <FeatureBlock
              title="Control your identity"
              Icon={OnChainIcon} // Added SVG Icon
              features={[
                "Empower Yourself as Your Own Bank - Exercise direct control over your identity with absolutely no intermediaries.",
                "Prioritize Privacy Above All - Dictate the terms of your personal data sharing, ensuring your privacy is always in your hands.",
                "Celebrate Your Uniqueness - Craft a digital ID that is as unstoppable and distinctive as you are.",
              ]}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <FeatureBlock
              title="Multichain by default"
              Icon={IdentityFabricIcon} // Added SVG Icon
              features={[
                "One Identity, All Chains - Access every blockchain with a single, unified identity.",
                "LayerZero Integration - Experience true interoperability with seamless cross-chain transactions.",
                "Future-Proof Framework - Built for the evolving web, ensuring your identity thrives on any blockchain.",
              ]}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <FeatureBlock
              title="Progressive Commitment"
              Icon={SecurityIcon} // Added SVG Icon
              features={[
                "Zero-Cost Onboarding - Begin with no upfront costs and decide what features you need, when you need them.",
                "Gradual Feature Adoption - Unlock additional capabilities as your requirements grow, ensuring you only pay for what you use.",
                "Opt-In Flexibility - Enjoy the freedom to choose advanced features at your own pace, enhancing your experience without compulsory commitments.",
              ]}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <FeatureBlock
              title="Easy Integration"
              Icon={AccessControlIcon} // Added SVG Icon
              features={[
                "Single Contract View - Simplify authorization checks with just one contract view needed.",
                "Universal Wallet Compatibility - No special wallet required; any EVM account suffices.",
                "Comprehensive Support - Benefit from extensive documentation and SDKs available in multiple languages.",
              ]}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <FeatureBlock
              title="Decentralized and Secure"
              Icon={DecentralizedIcon} // Added SVG Icon
              features={[
                "Fully On-Chain Operation - dgenDID operates entirely on the blockchain, ensuring no off-chain dependencies can compromise its integrity or availability.",
                "Immutable Security - With every transaction and operation verifiable on the blockchain, dgenDID offers unmatched security and trust.",
                "Unstoppable by Design - Built with decentralization at its core, dgenDID cannot be halted or censored, guaranteeing continuous operation.",
              ]}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <FeatureBlock
              title="User-Centric Design"
              Icon={UserCentricIcon} // Added SVG Icon
              features={[
                "Zero Cost Onboarding - Lowering the barrier to advanced identity infrastructure adoption at no initial cost.",
                "EVM Native - Seamlessly integrates with the Ethereum Virtual Machine, central to DeFi and Web3.",
                "User Empowerment - Empowers users with full control over their digital identities, ensuring privacy and security in the digital world.",
              ]}
            />
          </GridItem>
        </Grid>
      </Flex>
      
      <Flex justify="center" my={10}>
        <Box width="80%" textAlign="center">
          <DgenDIDInfo />
        </Box>
      </Flex>

      <Flex justify="center" my={10}>
        <Box width="80%" textAlign="center">
          <DgenDIDIntegration />
        </Box>
      </Flex>

      <Flex justify="center" my={10}>
        <Box width="80%" textAlign="center">
          <DgenDIDBenefits />
        </Box>
      </Flex>

      <Flex justify="center" my={10}>
        <Box width="80%" textAlign="center">
          <TeamInfo />
        </Box>
      </Flex>  

      <Flex justify="center" my={10}>
        <Box width="80%" textAlign="center">
          <Roadmap />
        </Box>
      </Flex>      
      
      <Footer />
    </>
  );
}
interface FeatureBlockProps {
  title: string;
  features: string[];
  Icon?: React.ElementType; // Optional Icon prop
}

const FeatureBlock: React.FC<FeatureBlockProps> = ({ title, features, Icon }) => {
  return (
    <Flex my={10} direction="column" align="left">
      <Flex align="center">
        {Icon && <Icon />} {/* Conditionally render the Icon if it's passed */}
        <Heading as="h2" size="xl" my={5} ml={Icon ? 3 : 0}>
          {title}
        </Heading>
      </Flex>
      <List spacing={3} display="inline-block" textAlign="left">
        {features.map((feature, index) => (
          <ListItem key={index} display="flex" alignItems="start">
            <ListIcon as={CheckCircleIcon} color="green.500" mt="4px" />
            <Text>{feature}</Text>
          </ListItem>
        ))}
      </List>
    </Flex>
  );
};

const DgenDIDInfo: React.FC = () => {
  return (
    <Flex id="what-is-it" my={10} direction="row" align="center">
      
      <Box width="85%" textAlign="left" pl={5}>
        <Heading as="h2" size="xl" my={5}>
          What is dgenDID?
        </Heading>
        <Text fontSize="lg" textAlign="justify" p={2}>
          dgenDID is a multi-chain decentralized identifier (DID) standard that revolutionizes digital identity through its fully on-chain, low-cost, and progressively adaptable architecture. Unlike traditional methods, dgenDID operates across multiple blockchain ecosystems, eliminating reliance on off-chain infrastructure and ensuring a seamless, decentralized experience for users worldwide.
        </Text>
        <Text fontSize="lg" textAlign="justify" p={2}>
          This groundbreaking approach enables users to bootstrap a blockchain-based Smart Wallet with minimal friction and cost. By leveraging smart contracts and on-chain data storage, dgenDID simplifies the implementation and use of DIDs, enhancing security and trustworthiness by ensuring all operations are verifiable on the blockchain.
        </Text>
        <Text fontSize="lg" textAlign="justify" p={2}>
          dgenDID addresses the limitations of existing DID standards by offering a fully decentralized, scalable, and user-centric approach to digital identity. It prioritizes on-chain operations, multi-chain interoperability, and progressive commitment, setting a new standard for decentralized identity.
        </Text>
      </Box>
      <Box width="15%">
        <Image src={BlockchainBlocks} alt="Blockchain Blocks" />
      </Box>
    </Flex>
  );
};


const DgenDIDIntegration: React.FC = () => {
  return (
    <Flex my={10} direction={{ base: "column", md: "row" }} align="center">
      <Box width={{ base: "100%", md: "15%" }} textAlign="center">
        <Image src={Chained} alt="Blockchain Integration" />
      </Box>
      <Box width={{ base: "100%", md: "85%" }} pl={{ md: 5 }}>
        <Heading as="h2" size="xl" textAlign="center" my={5}>
          Easy Integration with dgenDID
        </Heading>
        <Text fontSize="lg" textAlign="justify" p={2}>
          Integrating dgenDID with both on-chain and off-chain applications is remarkably straightforward, ensuring developers can seamlessly incorporate decentralized identity into their projects. dgenDID's flexible architecture is designed to support a wide range of use cases, making it an ideal solution for enhancing user identity verification processes.
        </Text>
        <Text fontSize="lg" textAlign="justify" p={2}>
          For on-chain applications, dgenDID provides a robust set of smart contracts and SDKs that facilitate direct interaction with blockchain networks. This allows for the easy creation, management, and verification of decentralized identities, all within the blockchain ecosystem.
        </Text>
        <Text fontSize="lg" textAlign="justify" p={2}>
          Off-chain applications can also benefit from dgenDID's capabilities through its API services, which enable secure and reliable communication between the blockchain and traditional web infrastructures. This ensures that applications outside the blockchain can still leverage the power of decentralized identities for authentication, authorization, and more.
        </Text>
        <Text fontSize="lg" textAlign="justify" p={2}>
          By bridging the gap between on-chain and off-chain environments, dgenDID offers a comprehensive solution that simplifies the integration of decentralized identities across various platforms. Its user-friendly approach ensures that developers can focus on creating innovative applications without worrying about the complexities of digital identity management.
        </Text>
      </Box>
    </Flex>
  );
};

const DgenDIDBenefits: React.FC = () => {
  return (
    <Flex my={10} direction={{ base: "column", md: "row" }} align="center">
      
      <Box width={{ base: "100%", md: "80%" }} textAlign="center">
        <Heading as="h2" size="xl" my={5}>
          The Benefits of dgenDID
        </Heading>
        <Text fontSize="lg" textAlign="justify" p={2}>
          dgenDID not only simplifies the process of digital identity management but also brings a host of benefits that enhance user experience and security. Its decentralized nature ensures that users have full control over their digital identities, reducing the risk of identity theft and fraud.
        </Text>
        <Text fontSize="lg" textAlign="justify" p={2}>
          The interoperability of dgenDID across multiple blockchain ecosystems allows for a seamless experience, enabling users to utilize their digital identities across various platforms without the need for multiple verifications. This interoperability also opens up new avenues for cross-chain applications and services, further expanding the utility and reach of digital identities.
        </Text>
        <Text fontSize="lg" textAlign="justify" p={2}>
          Furthermore, dgenDID's on-chain architecture ensures that all transactions and identity verifications are transparent and immutable, providing a level of security and trustworthiness that is unparalleled in traditional identity management systems. This transparency fosters trust among users and platforms, making dgenDID a cornerstone for the future of decentralized digital identity.
        </Text>
      </Box>
      <Box width={{ base: "100%", md: "15%" }} textAlign="center">
        <Image src={Compact} alt="Benefits of dgenDID" />
      </Box>
    </Flex>
  );
};


const TeamInfo: React.FC = () => {
  return (
    <Flex id="team-info" my={10} direction={{ base: "column", md: "row" }} align="center">
      
      <Box width={{ base: "100%", md: "15%" }} textAlign="center">
        <Image src={BlockchainBlocks} alt="Blockchain Blocks" />
      </Box>
      <Box width={{ base: "100%", md: "80%" }} textAlign="center">
        <Heading as="h2" size="xl" my={5}>
          Team
        </Heading>
        <Text fontSize="lg" textAlign="justify" p={2}>
          At the core of dgenDID's innovation lies our team, a group of anonymous visionaries dedicated to redefining the landscape of digital identity. Our team members, though unseen, are the unsung heroes behind the seamless and secure digital experiences that dgenDID provides.
        </Text>
        <Text fontSize="lg" textAlign="justify" p={2}>
          Each member brings a unique set of skills, bound by a common goal to empower users with control over their digital identities. By remaining anonymous, our team ensures that the focus remains on the technology and its benefits, rather than individual accolades. This approach aligns with our philosophy of creating an unstoppable and decentralized identity solution where the only identity that matters is the one owned by the user.
        </Text>
        <Text fontSize="lg" textAlign="justify" p={2}>
          Our commitment to privacy and security is mirrored in our operational model. The anonymity of our team is a bold statement in our trust in blockchain technology and its power to maintain integrity without the need for public-facing figures.
        </Text>
      </Box>
    </Flex>
  );
};

const Roadmap: React.FC = () => {
  return (
    <Flex id="roadmap" my={10} direction={{ base: "column", md: "row" }} align="center">
      <Box width={{ base: "100%", md: "80%" }} textAlign="center">
        <Heading as="h2" size="xl" my={5}>
          Roadmap
        </Heading>
        <Text fontSize="lg" textAlign="justify" p={2}>
          Our journey towards revolutionizing digital identity management is structured with strategic milestones:
        </Text>
        <List spacing={3} my={5} textAlign={"left"}>
          <ListItem display="flex" alignItems="start">
            <ListIcon as={CheckCircleIcon} color="green.500" mr={2} mt={1} />
            <Text>Initial Deployment to Layer 2 Networks - Our first phase focuses on deploying dgenDID to select Layer 2 solutions, optimizing for speed and cost efficiency.</Text>
          </ListItem>
          <ListItem display="flex" alignItems="start">
            <ListIcon as={CheckCircleIcon} color="green.500" mr={2} mt={1} />
            <Text>Expansion to Ethereum Mainnet - Following successful L2 deployments, we will bridge to Ethereum, ensuring robust security and wider adoption.</Text>
          </ListItem>
          <ListItem display="flex" alignItems="start">
            <ListIcon as={CheckCircleIcon} color="green.500" mr={2} mt={1} />
            <Text>Enabling Identity Bridging via LayerZero - To enhance interoperability, we will implement identity bridging, allowing seamless identity usage across different blockchains.</Text>
          </ListItem>
          <ListItem display="flex" alignItems="start">
            <ListIcon as={CheckCircleIcon} color="green.500" mr={2} mt={1} />
            <Text>Introduction of Identity Bridge Operators - In our final phase, we will introduce decentralized operators to reduce costs and friction, further enhancing user experience and utility through staking mechanisms.</Text>
          </ListItem>
        </List>
      </Box>
      <Box width={{ base: "100%", md: "15%" }} textAlign="center">
        <Image src={Chained} alt="Roadmap Visualization" />
      </Box>
    </Flex>
  );
};

